import { apiErrorMessage, isNullUndefinedEmpty } from '../helpers/helpers';

import { checkTableSwipeDisplay } from '../common/tables';
import { displayErrorMessage } from '../helpers/displayError';
import userStateManager from '../helpers/stateManager';

export const component = async () => {
    const momentModule = await import('moment-mini');
    window.moment = momentModule.default;

    var $myDetailsTable = $('#my-details-table');
    var $covidTable = $('#my-covid-table');

    // FIRER-21
    // Response data inconsistency on userStateManager.getUserProfileData
    // So direct api call instead
    $.ajax({
        type: 'GET',
        url: '/dfes-api/UserAPI/GetUserProfileData',
        dataType: 'json',
        success: (response, status) => {
            const data = response.data;

            if (data.apiProblemOccured) {
                displayErrorMessage($myDetailsTable);
                $('.update-my-details-wrapper').hide();
                return;
            }

            var tableHTML = getMyDetailsTableHtml(data.ProfileInfo);
            var $table = $('<div class="my-details__table">').html(tableHTML);

            $myDetailsTable.find('.loader-wrapper').hide();
            $myDetailsTable.append($table);

            $covidTable.find('.loader-wrapper').hide();
            $covidTable.append(
                `
                <table class="dfes-o-table">
                <thead>
                    <tr>
                        <th>Vaccination Status</th>
                        <th>Most Recent Vaccination Date</th>
                    </tr>
                </thead>
                <tbody>
                    <td>${data.ProfileInfo.VaccineType ? data.ProfileInfo.VaccineType : '-'}</td>
                    <td>${data.ProfileInfo.VaccineDate ? moment(data.ProfileInfo.VaccineDate).format('DD/MM/YYYY') : '-'}</td>
                </tbody>
                </table>`
            );
            
            checkTableSwipeDisplay(); //Mobile swipe message
        },
        error: error => {
            console.error('There was an issue generating the My Details table: ', error);
            displayErrorMessage($myDetailsTable);
        }
    });
};

function getMyDetailsTableHtml(content) {
    //Allow rendering empty table
    if (content === null) {
        content = {};
    }

    function getDetailRow(label, value) {
        if (value !== null && typeof value !== 'undefined') {
            return `
                <tr>
                    <th scope="row">${label}</th>
                    <td>${value}</td>
                </tr>
            `;
        } else {
            return `
                <tr>
                    <th scope="row">${label}</th>
                    <td>-</td>
                </td>
            `;
        }
    }
    function getVaccinationRows(dataObj) {
        var show = false;

        if (!isNullUndefinedEmpty(dataObj.ShowVaccinationDetails)) {
            show = dataObj.ShowVaccinationDetails;
        }

        if (show) {
            var vaccineType = '-';
            var vaccineDate = '-';
            if (!isNullUndefinedEmpty(dataObj.VaccineType)) {
                vaccineType = dataObj.VaccineType;
            }
            if (!isNullUndefinedEmpty(dataObj.VaccineDate)) {
                vaccineDate = moment(dataObj.VaccineDate).format('DD/MM/YYYY');
            }

            return `
                ${getDetailRow('Vaccine Type', vaccineType)}
                ${getDetailRow('Vaccine Date', vaccineDate)}
            `;
        }

        return ``;
    }
    function getServiceNoRow(dataObj) {
        var volunteerNo = `-`;
        if (dataObj.VolunteerNo) {
            volunteerNo = dataObj.VolunteerNo;
        }
        return `
                <tr>
                    <th scope="row">Volunteer Number</th>
                    <td>${volunteerNo}</td>
                </tr>
            `;
    }
    function getPhoneRow(mobile, ah) {
        if ((mobile !== null && typeof mobile !== 'undefined') || (ah !== null && typeof ah !== 'undefined')) {
            return `
                <tr>
                    <th scope="row">Phone</th>
                    <td>
                        ${mobile !== null && typeof mobile !== 'undefined' ? `<div class="mobile">${mobile}</div>` : ''}
                        ${ah !== null && typeof ah !== 'undefined' ? `<div class="landline">${ah}</div>` : ''}
                    </td>
                </tr>
            `;
        } else {
            return `
                <tr>
                    <th scope="row">Phone</th>
                    <td>-</td>
                </tr>
            `;
        }
    }

    //BGUs
    var strBgus = null;
    if (content.BGUs !== null && typeof content.BGUs !== 'undefined' && content.BGUs.length > 0) {
        content.BGUs.forEach(function (bgu, index) {
            if (index === 0) {
                strBgus = bgu.BguName;
            } else {
                strBgus += ', ' + bgu.BguName;
            }
        });
    } else {
        strBgus = '-';
    }

    var strChildrenCheck = null;
    if (!isNullUndefinedEmpty(content.WorkingwithChildrenCheck)) {
        strChildrenCheck = content.WorkingwithChildrenCheck;

        if (!isNullUndefinedEmpty(content.WorkingWithChildrenExpiryDate)) {
            strChildrenCheck += ` - Expires ${moment(content.WorkingWithChildrenExpiryDate).format('DD/MM/YYYY')}`;
        }
    }

    var vaccineType = '-';
    var vaccineDate = '-';
    if (!isNullUndefinedEmpty(content.VaccineType)) {
        vaccineType = content.VaccineType;
    }
    if (!isNullUndefinedEmpty(content.VaccineDate)) {
        vaccineDate = moment(content.VaccineDate).format('DD/MM/YYYY');
    }

    //Dob check for moment (it uses current date if it's null/undefined)
    const dateStr =
        content.DateOfBirth !== null && typeof content.DateOfBirth !== 'undefined' ? moment(content.DateOfBirth).format('DD/MM/YYYY') : '-';

    // FIRER-21: directly add gender value as display text
    // https://equilibrium.atlassian.net/browse/FIRER-21?focusedCommentId=275027

    return `
        <table class="dfes-o-table">
            <tbody>
                ${getDetailRow('Name', content.Name)}
                ${getDetailRow('Gender', content.Gender)}
                ${getDetailRow('Date of Birth', dateStr)}
                ${getServiceNoRow(content)}
                ${getDetailRow('BGU(s)', strBgus)}
                ${getDetailRow('Occupation', content.Occupation)}
                ${getDetailRow('Drivers Licence', content.DriversLicense)}
                ${getDetailRow('Personal Email', content.PersonalEmail)}
                ${getPhoneRow(content.PhoneMobile, content.PhoneAH)}
                ${getDetailRow('Residential Address', content.ResidentialAddress)}
                ${getDetailRow('Emergency Contact', content.EmergencyContact)}
                ${getDetailRow('Language Spoken', content.LanguageSpoken)}
                ${getDetailRow('Other Information', content.OtherInfo)}
                ${getDetailRow('Ethnic Background', content.EthnicBackground)}
                ${getDetailRow('Aboriginal/Torres Strait Islander', content.IsAboriginalTi)}
                ${getDetailRow('Working with Children Check', strChildrenCheck)}
            </tbody>
        </table>
	`;
}
